<template>
  <form>
    <div v-for="field in formFields" :key="field.name">
      <label>{{ field.label }}</label>
      <input
        :type="field.type"
        :placeholder="field.placeholder"
        :name="field.name"
      />
    </div>
    <button type="submit">Submit</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      formFields: [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          placeholder: 'Enter your name'
        },
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          placeholder: 'Enter your email'
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          placeholder: 'Enter your password'
        }
      ]
    };
  }
};
</script>